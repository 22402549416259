import { inject, Injectable } from '@angular/core';
import { LoginFormValue } from './login-form/login-form.component';
import { RegisterFormValue } from './register-form/register-form.component';
import { AuthService } from '@smartops-monorepo/client-common';
import { Router } from '@angular/router';
import { delay } from 'rxjs';
import { NotificationService } from '@smartops-monorepo/client-common/service';
import { TextManager } from '@smartops-monorepo/core-common';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private readonly authService: AuthService = inject(AuthService);
  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly router: Router = inject(Router);

  /**
   * Function that is responsible for logging in the user.
   *
   * @param {LoginFormValue} loginFormValue - The login form value.
   */
  login(loginFormValue: LoginFormValue): void {
    const { email, password, remember } = loginFormValue;
    this.authService.getSession(email, password, remember)
      .pipe(delay(500))
      .subscribe({
        next: () => this.navigateToApp(),
        error: (error: Error) => {
          this.displaySomethingWentWrongNotification();
          console.error('Error!', error);},
      });
  }

  /**
   * Function that is responsible for registering a new user.
   *
   * @param {RegisterFormValue} value - The register form value.
   */
  register(value: RegisterFormValue) {
    const { email, firstName, lastName, password } = value;
    this.authService.register(email, password, firstName, lastName).subscribe({
      next: ({ accessToken }: { accessToken: string }) => {
        console.log('Registered!', 'Tokens:', { accessToken });
        const textCode: string = 'label:registrationSuccessfulMessage';
        const message: string = TextManager.getTextFromCode(textCode, true);
        this.notificationService.displayNotification('success', message);
        this.navigateToApp();
      },
      error: (error: Error) => {
        this.displaySomethingWentWrongNotification();
        console.error('Error!', error);
      },
    });
  }

  /**
   * Function that navigates the user to the app route after a successful login or registration. That route shall then determine
   * what functionality the user has access too and based of that it will determine to what domain the user will be redirected.
   *
   * @private
   */
  private navigateToApp() {
    this.router.navigate(['app']).then(() => null);
  }

  /**
   * Function that is used when an error occurred during any of the actions to display a notification about this. But it is not good
   * practise to communicate information to the user about what went wrong during authentication, so we just display a generic message.
   *
   * @private
   */
  private displaySomethingWentWrongNotification() {
    const textCode: string = 'warning:somethingWentWrong';
    const message: string = TextManager.getTextFromCode(textCode, true);
    this.notificationService.displayNotification('error', message);
  }
}
