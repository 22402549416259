import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { ActivatedRoute } from '@angular/router';
import { CardComponent } from '@smartops-monorepo/ui-components';

@Component({
  selector: 'spa-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClientCommonModule, NzLayoutModule, CardComponent],
})
export class AuthComponent {
  private route: ActivatedRoute = inject(ActivatedRoute);
  background: string = this.route.snapshot.data['background'];
}
