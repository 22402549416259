<div nz-flex [nzVertical]="true" nzAlign="center" nzJustify="center" nzGap="middle">
  <div nz-flex nzJustify="center" nzAlign="center" style="flex: 1;">
    <spa-ui-avatar
      nz-flex
      nzJustify="center"
      nzAlign="center"
      [size]="64"
      styling="background-color: #FAE88C; color: #200450;"
    ></spa-ui-avatar>
  </div>
  <div class="spa-auth-title-container">
    <div class="spa-auth-title">
      {{ 'label:welcomeUser' | text }}
    </div>
    <div class="spa-auth-sub-title">
      {{ 'label:loginToYourAccount' | text }}
    </div>
  </div>
</div>
<form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-control [nzErrorTip]="'label:email' | text">
      <nz-input-group nzPrefixIcon="mail">
        <input
          type="text"
          nz-input
          formControlName="email"
          [placeholder]="'label:email' | text | placeholder"
          email
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="'label:password' | text:false | inputValueReminder">
      <nz-input-group nzPrefixIcon="lock">
        <input
          nz-input
          type="password"
          formControlName="password"
          [placeholder]="'label:password' | text | placeholder"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <div nz-flex nzJustify="space-between" nzAlign="center" class="login-form-actions-row">
    <div nz-flex nzAlign="center" nzJustify="flex-start" nzGap="small">
      <spa-ui-checkbox formControlName="remember">
      </spa-ui-checkbox>
      <span>{{ 'label:rememberMe' | text }}</span>
    </div>
    <a class="login-form-forgot" nz-flex nzAlign="flex-end" [nzVertical]="true">
      {{ 'label:forgotPassword' | text }}
    </a>
  </div>
  <div class="login-form-button-row" nz-flex nzJustify="flex-start" nzAlign="center">
    <spa-ui-button style="flex: 1;" [type]="'primary'">
      {{ 'label:login' | text }}
    </spa-ui-button>
  </div>
  <div nz-flex nzJustify="flex-start" nzAlign="center" nzGap="small">
    {{ 'label:or' | text }}
    <a [routerLink]="['/register']" >
      {{ 'label:registerNow' | text }}
    </a>
  </div>
</form>
