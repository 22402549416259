<form nz-form [formGroup]="registrationForm" (ngSubmit)="submitForm()">

  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="email">E-mail</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="('warning:inputIsNotValidType' | text) + ' ' + ('label:email' | text)!">
      <input nz-input formControlName="email" id="email" email/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="firstName" nzRequired>
      <span>First Name</span>
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'label:firstName' | text:false | inputValueReminder">
      <input nz-input id="firstName" formControlName="firstName"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="lastName" nzRequired>
      <span>Last Name</span>
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'label:lastName' | text:false | inputValueReminder">
      <input nz-input id="lastName" formControlName="lastName"/>
    </nz-form-control>
  </nz-form-item>

  <!--  <nz-form-item>-->
  <!--    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Date Of Birth</nz-form-label>-->
  <!--    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please select your date of birth!">-->
  <!--      <nz-date-picker-->
  <!--        formControlName="dateOfBirth"-->
  <!--        id="dateOfBirth"-->
  <!--        nzFormat="dd/MM/yyyy"-->
  <!--        nzPlaceHolder="Select date"-->
  <!--      ></nz-date-picker>-->
  <!--    </nz-form-control>-->
  <!--  </nz-form-item>-->

  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="password" nzRequired>
      {{ 'label:password' | text }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'label:password' | text:false | inputValueReminder">
      <input nz-input type="password" id="password" formControlName="password" (ngModelChange)="updateConfirmValidator()" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="checkPassword" nzRequired>
      {{ 'label:confirmPassword' | text }}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
      <input nz-input type="password" formControlName="checkPassword" id="checkPassword"/>
      <ng-template #errorTpl let-control>
        @if(control.hasError('required')) {
          {{ 'label:pleaseConfirmPassword' | text }}
        } @else if(control.hasError('confirm')) {
          {{ 'label:passwordsDoNotMatch' | text }}
        }
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item nz-row>
    <nz-form-control [nzSpan]="14" [nzOffset]="8">
      <spa-ui-checkbox formControlName="agree">
        <span>
          {{ 'label:haveRead' | text }}
          <a>{{ 'label:agreement' | text }}</a>
        </span>
      </spa-ui-checkbox>
    </nz-form-control>
  </nz-form-item>

  <nz-row>
    <nz-col [nzSpan]="14" [nzOffset]="8">
      <spa-ui-button type="primary" [disabled]="!registrationForm.valid">
        {{ 'label:register' | text }}
      </spa-ui-button>
      {{ 'label:or' | text }}
      <a [routerLink]="['/login']">
        {{ 'label:login' | text }}
      </a>
    </nz-col>
  </nz-row>

</form>
