import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { ButtonComponent, CheckboxComponent } from '@smartops-monorepo/ui-components';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';

export type RegisterFormValue = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
}

@Component({
  selector: 'spa-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    ClientCommonModule, NzInputModule, NzLayoutModule, RouterModule,
    ButtonComponent, CheckboxComponent, NzFormModule, NzGridModule,
  ],
})
export class RegisterFormComponent {
  registrationForm: FormGroup<{
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    password: FormControl<string>;
    checkPassword: FormControl<string>;
    agree: FormControl<boolean>;
  }>;

  /**
   * Constructor for the RegisterFormComponent.
   *
   * @param {NonNullableFormBuilder} fb - The form builder.
   * @param {ChangeDetectorRef} cd - The change detector.
   * @param {LoginService} loginService - The login service.
   * @constructor
   */
  constructor(private fb: NonNullableFormBuilder, private cd: ChangeDetectorRef, private loginService: LoginService) {
    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      checkPassword: ['', [Validators.required, this.confirmationValidator]],
      agree: [false, [Validators.requiredTrue]],
    });
  }

  /**
   * Submits the form.
   */
  submitForm(): void {
    if (this.registrationForm.valid) {
      const { email, firstName, lastName, password } = this.registrationForm.value;
      this.loginService.register({ email, firstName, lastName, password } as RegisterFormValue);
    }
  }

  /**
   * Updates the confirm validator.
   */
  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => {
      this.registrationForm.controls.checkPassword.updateValueAndValidity();
      this.cd.markForCheck();
    });
  }

  /**
   * Getter for the confirmation validator.
   *
   * @param {AbstractControl} control - The control to validate.
   * @return {{[p: string]: boolean}} - The validation result.
   */
  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.registrationForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
