import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouterModule } from '@angular/router';
import en from '@angular/common/locales/en';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AntDesignModules } from '@smartops-monorepo/ui-core/ant-design';
import { ClientCommonModule, CoreModule as ClientCommonCoreModule, LocalStorageService, StorageService } from '@smartops-monorepo/client-common';
import { CoreModule as UICoreModule } from '@smartops-monorepo/ui-core';
import { CLIENT_CONFIG, ClientConfig, ENVIRONMENT_CONFIG, EnvironmentConfig } from '@smartops-monorepo/client-common/config';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { environment } from '../environments/environment';

registerLocaleData(en);

const CLIENT_CONFIG_VALUE: ClientConfig = {
  API_ENDPOINT: environment.apiEndpoint,
  API_PREFIX: environment.apiPrefix,
  WEBSITE_URL: environment.websiteUrl,
};

const ENVIRONMENT_CONFIG_VALUE: EnvironmentConfig = {
  production: environment.type === 'production',
  type: environment.type,
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CommonModule,
    RouterModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'top', useHash: false }),
    AntDesignModules.forRoot(),
    ClientCommonCoreModule.forRoot(),
    ClientCommonModule,
    UICoreModule,
    NzLayoutModule,
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  providers: [
    provideHttpClient(withFetch()),
//    provideClientHydration(
//      withHttpTransferCacheOptions({
//        includePostRequests: true,
//      }),
//    ),
    {
      provide: CLIENT_CONFIG,
      useValue: CLIENT_CONFIG_VALUE,
    },
    {
      provide: ENVIRONMENT_CONFIG,
      useValue: ENVIRONMENT_CONFIG_VALUE,
    },
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    CookieService,
  ],
  exports: [AppComponent],
})
export class AppModule {
}
