<nz-layout [style.background-color]="background">
  <nz-content>
    <spa-ui-card
      [style.background-color]="background"
      [borderRadius]="16"
      [bodyStyle]="{ padding: '48px' }">
      <router-outlet></router-outlet>
    </spa-ui-card>
  </nz-content>
</nz-layout>
