import { Route } from '@angular/router';
import { AuthGuard, DomainGuard } from '@smartops-monorepo/client-common';
import { AuthComponent, LoginFormComponent, RegisterFormComponent } from '@smartops-monorepo/login';
import { ShiftPlanner, Worker } from '@smartops-monorepo/types/user';
import { AuthenticatedComponent } from './authenticated/authenticated.component';
import { LoadingPageComponent } from './authenticated/loading-page.component';

export const AppRoutes: Route[] = [
  {
    path: '',
    component: AuthComponent,
    data: { background: '#D3D9F4' },
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        component: LoginFormComponent,
      },
      {
        path: 'register',
        component: RegisterFormComponent,
      }
    ]
  },
  {
    path: 'app',
    component: AuthenticatedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoadingPageComponent,
      },
      {
        path: 'shift-planner',
        canActivate: [DomainGuard],
        data: { roles: [ShiftPlanner], },
        loadChildren: () => import('@smartops-monorepo/shift-planner')
          .then((m) => m.ShiftPlannerModule),
      },
      {
        path: 'worker',
        canActivate: [DomainGuard],
        data: { roles: [Worker], },
        loadChildren: () => import('@smartops-monorepo/worker')
          .then((m) => m.WorkerModule),
      }
    ],
  },
];
