import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'spa-authenticated',
  imports: [RouterModule],
  template: '<router-outlet></router-outlet>',
  standalone: true,
})
export class AuthenticatedComponent {
}
