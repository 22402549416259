import { ChangeDetectionStrategy, Component, HostBinding, inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { AvatarComponent, ButtonComponent, CheckboxComponent } from '@smartops-monorepo/ui-components';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { NzFlexDirective } from 'ng-zorro-antd/flex';

//const email: string = 'e@mail.com';
const email: string = 'worker@test-factory.com';
//const email: string = 'planner@test-factory.com';

export type LoginFormValue = { email: string; password: string, remember: boolean }

@Component({
  selector: 'spa-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClientCommonModule, ButtonComponent, CheckboxComponent, NzFormModule, NzGridModule, NzInputModule, NzLayoutModule, RouterModule, NzFlexDirective, AvatarComponent],
})
export class LoginFormComponent {
  @HostBinding('id') id = 'login-form';

  private fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);
  private loginService: LoginService = inject(LoginService);

//  controls: Structure = [
//    {
//      type: 'email',
//      path: 'email',
//      label: 'label:email',
//      name: 'email',
//      required: true,
//      prefixIcon: 'email',
//      tip: {
//        error: TextManager.createInputValueReminder('label:email'),
//      },
//    },
//    {
//      type: 'password',
//      path: 'password',
//      label: 'label:password',
//      name: 'password',
//      required: true,
//      prefixIcon: 'lock',
//      tip: {
//        error: TextManager.createInputValueReminder('label:password'),
//      },
//    },
//    {
//      type: 'row',
//      columns: [
//        { control: { type: 'checkbox', name: 'remember', path: 'remember', label: 'label:rememberMe' }, style: { flex: 1 } },
//        { control: { type: 'checkbox', name: 'remember', path: 'remember', label: 'label:rememberMe' }, style: { flex: 1 } }
//      ],
////      columns: [
////        { control: { name: 'name', path: 'job.name', label: 'label:name', type: 'text', required: true, }, style: { flex: 1 } },
////        { control: { name: 'color', path: 'color', type: 'color-picker', required: true }, style: { flex: '40px 0 0' } }
////      ],
//    },
//    {
//      type: 'checkbox',
//      label: 'Remember me',
//      name: 'remember',
//      placeholder: 'Remember me',
//      required: false,
//    },
//  ]

  validateForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
    remember: FormControl<boolean>;
  }> = this.fb.group({
    email: [email, [Validators.required, Validators.email]],
    password: ['password', [Validators.required]],
//    email: ['', [Validators.required, Validators.email]],
//    password: ['', [Validators.required]],
    remember: [true],
  });


  /**
   * Constructor for the LoginFormComponent.
   */
  submitForm(): void {
    if (this.validateForm.valid) {
      const { email, password, remember } = this.validateForm.value;
      this.loginService.login({ email, password, remember } as LoginFormValue);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


}
