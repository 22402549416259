import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrentUserService } from '@smartops-monorepo/client-common/service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientCommonModule } from '@smartops-monorepo/client-common';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'spa-loading-page',
  standalone: true,
  imports: [ClientCommonModule, NzSpinModule],
  template: '<nz-spin nzSimple nzSize="large"></nz-spin>',
  styles: `
    :host {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(0 0 0 / 82%); // #f2f2f2;
    }
    
    nz-spin {
      margin: 0 auto;
      zoom: 2.5;
    }
  `,
  encapsulation: ViewEncapsulation.Emulated,
})
export class LoadingPageComponent implements OnInit {
  private readonly currentUserService: CurrentUserService = inject(CurrentUserService);
  private readonly router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    this.currentUserService.getCurrentUser().subscribe({
      next: async (user) => {
        const domainToNavigateTo = CurrentUserService.getDomainToNavigateTo(user.roles);
        await this.router.navigate([domainToNavigateTo], {  relativeTo: this.route });
      }
    });
  }
}
